import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { MessageService } from 'primeng/api';
import { LayoutService } from 'src/app/layout/services/layout.service';
import { AWSS3Service } from '../service/azure-blob-storage.service';
import { SharedService } from '../service/shared.service';
import { CollaborateService } from 'src/app/collaborate/services/collaborate.service';
import { file } from 'jszip';
import { url } from 'inspector';
import { Observable } from 'rxjs';
import { NgTinyUrlService } from 'ng-tiny-url';
import { chuck_size, one_GB, upload_size } from 'src/app/constant/constant';
import { PresignedPost } from 'aws-sdk/clients/s3';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['../../../../css/mystyle.css',
  '../../../../vendor/bootstrap/css/bootstrap.min.css',
  '../../../../vendor/fontawesome-free/css/all.min.css'],
  providers: [MessageService]
})
export class UploaderComponent implements OnInit{
  @Input() files:any = [];
  @Output() showUploaderProgress = new EventEmitter<string>();
  @Output() hideUploader = new EventEmitter<string>();
  @Output() dashboardLoadEvent = new EventEmitter<any>();
  optionsAnimation: AnimationOptions = {
    path: "assets/animations/Globe to attach file.json"
  };
  // public files: any = [];
  public allFiles:any[] = [];
  // public emailTo : any;
  public description: string = '';
  public localStorage :any;
  // public fileUrlData : any = [];
  public fileURLWithName : any =[];
  public progressStart = false;
  public progress = 0;
  public currentFileName = '';
  public usedBytes:any;
  public remainingBytes:any;
  public totalBytes:any;
  public currentStorage:any;
  public inputEmail = false;
  public check = false;
  public sendFileForm: FormGroup;
  public isSubmitted = false;
  paramsData: any;
  ispaid: any = true;
  tempProgressValue: any = 0;
  tempPValue: any = 0;
  oncancel2: any =false;
  furl: any;
  shortUrl: any;
  ftype: any;
  tempTotalSize: any;
  paytype: any;
  selectedVid: any;


  constructor(private router:Router, 
    private layoutService:LayoutService,
    private messageService: MessageService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private tinyUrl: NgTinyUrlService,
    private service: CollaborateService,
    // private blobService: AzureBlobStorageService,
    private s3Service: AWSS3Service) {
      // this.sharedService.progressValue.subscribe(res=>this.progress = res)
      this.sendFileForm = new FormGroup({
        email: new FormControl('',[Validators.required,Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$"),this.validateEmail]),
      })
      
     }


  ngOnInit(): void {
    this.localStorage = JSON.parse(localStorage.getItem('userInfo') || '{}');
    this. loadInitialData();
    this.sharedService.oncanceluploadObservable().subscribe((isFalse: boolean) => {
      this.oncancel2 = isFalse;
    });
    this.sharedService.getUploadProcessModalObservable().subscribe((res: any) => {
      this.getURLFromAWS(res);
    });
    this.sharedService.getUpProcessModalObservable().subscribe((res: any) => {
      this.getURLFromAWS1(res);
    });
    this.sharedService.isPaid.subscribe(res=>this.ispaid = res);

  }

  loadInitialData(){
    this.sharedService.getFileSizeByUserId(this.localStorage.userId).subscribe(res=>{
      if(res){
        this.usedBytes = res.responseData ? Number(res.responseData.usedBytes) : 0;
        this.remainingBytes = res.responseData ? Number(res.responseData.remainingBytes) : 0;
        this.totalBytes = res.responseData ? Number(res.responseData.totalBytes) : 0;
      }
    }); 
  }

  onUpload(event:any) {
  if(localStorage.getItem('userInfo') === null){
    this.router.navigate(['auth/login']);
  }else{
    this.fileInArray(event.files[0]);
    this.showRemainingStorage();
  }
}

onDNDSelected(event:any){
  if(localStorage.getItem('userInfo') === null){
    this.router.navigate(['auth/login'])
  }else{
    this.fileInArray(event);
    this.showRemainingStorage();
  }
}

onSelected(event:any){
  if(localStorage.getItem('userInfo') === null){
    this.router.navigate(['auth/login'])
  }else{
  if(event.target.files.length > 0){
    this.fileInArray(event.target.files[0])
    this.showRemainingStorage();
  }}
}

inputToEmail(){
  this.inputEmail = !this.inputEmail
  this.check = this.inputEmail
  // this.sendFileForm.get('email')?.setValue('')
  if(!this.inputEmail){
    this.sendFileForm.get('email')?.setValidators([Validators.required,this.validateEmail]);
    this.sendFileForm.get('email')?.setValue('')
    this.sendFileForm.updateValueAndValidity();
    this.isSubmitted = false;
  }else{
    this.sendFileForm.get('email')?.clearValidators();
    this.sendFileForm.get('email')?.setValue('')
    this.sendFileForm.updateValueAndValidity();
    this.isSubmitted = false;
  }
 }

fromGlobe(){
  document.getElementById('fileInput')?.click();
  }

  remove(index:any){
    this.files.splice(index,1)
  }

fileInArray(file:File){
    if(file.size > upload_size){
    this.showSelectedFileExceedsLimit();
    }else{
      this.files.push(file);
    }
  }

showRemainingStorage(){
  for(let i=0 ;i<this.files.length;i++){
    this.remainingBytes = this.remainingBytes - Number(this.files[i].size)
  }
  this.currentStorage = this.remainingBytes;
}

sendFileGetURL(){
  this.isSubmitted = true;
  if(this.sendFileForm.valid){
    if(this.files.length != 0){
      this.showUploaderProgress.emit("1");
      this.progressStart=true;
      this.sharedService.progress.next(true);
      this.sharedService.setfiles(this.files);
      
    for(let i=0 ;i<this.files.length;i++){
      // this.getURL(this.files[i]);
      if (this.isLargeFile(this.files[i])) {
        this.getURLFromAWS1(this.files[i]);
      } else {
        this.getURLFromAWS(this.files[i]);
      }
    }
  }
  else{
    this.sendFileForm.reset();
    this.description = '';
    this.showFailure();
  }
  }
}

isLargeFile(file: File): boolean {
  console.log(" size",file.size)
  // Define a threshold size for large files, for example, 10 MB
  const thresholdSize = 10 * 1024 * 1024; // 10 MB in bytes
  return file.size > thresholdSize;
}

timeStamp(name:string){
  let time = new Date();
  const timeStamp = 'File_'+time.getFullYear()+(time.getMonth()+1)+time.getDate()+time.getTime()+'.'+(name.substring(name.lastIndexOf('.') + 1));
  let model = {
   originalName :name,
   fileName :timeStamp,
  //  fileURL: '',
  }
  this.fileURLWithName.push(model)
  return timeStamp
  }

//  async getURLFromAzure(file:File){
//   // this.sharedService.progress.next(true);
//   let Responseurl = await this.blobService.uploadFiles(file,this.timeStamp(file.name), () => {});
//   this.fileURLWithName.map((file:any)=>{if(file.fileName === Responseurl._name){file.fileURL = Responseurl.url}});
//   if(this.fileURLWithName.length === this.files.length && this.progress ===100 && this.checkFileURL()){
  
//   this.sendFileInfo();
//   }
//  }

async getURLFromAWS(file: File) {
  try {
    // this.sharedService.initFileProgress(file.name); // Initialize the progress for the file


  let Responseurl = await this.s3Service.uploadFiles(file, this.timeStamp(file.name), () => {},(progress: number) => {
    // You can handle progress updates here if needed
    console.log(`Upload progress: ${progress}%`);
    // You might want to update your UI with the progress value
    this.sharedService.setPValue(Math.round(progress));

    this.tempProgressValue = progress.toFixed(2);
    this.sharedService.setProgressValue(this.tempProgressValue);
   // Subscribe to totalSize and ensure it emits a value

  //  this.sharedService.setFileProgress(file.name, progress); // Update progress for the specific file

   this.sharedService.totalSize.subscribe({
    next: (res) => {
      // console.log('Total size emitted:', res);
      this.tempTotalSize = res || 0;

      if (!isNaN(this.tempTotalSize) && this.tempTotalSize > 0) {
        let total = this.tempTotalSize * (this.tempProgressValue / 100);
        this.sharedService.setupSize(total);
        // console.log(`Uploaded: ${total} bytes out of ${this.tempTotalSize} bytes`);
      } else {
        console.error('Invalid total size:', this.tempTotalSize);
      }
    },
    error: (err) => console.error('Error in totalSize observable:', err),
    complete: () => console.log('totalSize observable completed')
  });
  }
);
console.log("object",this.fileURLWithName.map((file: any) => { if (file.fileName === Responseurl.name) { file.fileURL = Responseurl.url } }))
  this.fileURLWithName.map((file: any) => { if (file.fileName === Responseurl.name) { file.fileURL = Responseurl.url } });
  // if (this.fileURLWithName.length === this.files.length && this.progress === 100 && this.checkFileURL()) {
  //   this.sendFileInfo();
  // }
  // this.sendFileInfo();
  // this.sharedService.removeFileProgress(file.name);

  this.sendFileInfoSinglefile(file,Responseurl.url);
} catch (error) {
  // Handle the error as needed
  // this.sharedService.removeFileProgress(file.name); // Remove progress tracking on error
  console.error('Error getting URL from AWS:', error);
} /* finally {
  // Always call sendFileInfo, even if an error occurs
  // this.sendFileInfo(file);
  
} */
}

async getURLFromAWS1(file: File) {
  try {
    this.sharedService.initFileProgress(file.name); // Initialize the progress for the file


    // Use the modified uploadFilesWithProgress method for multipart upload
    let Responseurl = await this.s3Service.uploadFiles1(file,this.timeStamp(file.name),() => {},(progress: number) => {
        // You can handle progress updates here if needed
        console.log(`Upload progress: ${progress}%`);
        this.sharedService.setPValue(Math.round(progress));

        // You might want to update your UI with the progress value
        this.tempProgressValue = progress.toFixed(2);
        this.sharedService.setProgressValue(this.tempProgressValue);
         // Subscribe to totalSize and ensure it emits a value
    this.sharedService.totalSize.subscribe({
      next: (res) => {
        // console.log('Total size emitted:', res);
        this.tempTotalSize = res || 0;

        if (!isNaN(this.tempTotalSize) && this.tempTotalSize > 0) {
          let total = this.tempTotalSize * (this.tempProgressValue / 100);
          this.sharedService.setupSize(total);
          // console.log(`Uploaded: ${total} bytes out of ${this.tempTotalSize} bytes`);
        } else {
          console.error('Invalid total size:', this.tempTotalSize);
        }
      },
      error: (err) => console.error('Error in totalSize observable:', err),
      complete: () => console.log('totalSize observable completed')
    });

    // this.sharedService.setFileProgress(file.name, progress); // Update progress for the specific file

      }
    );

    // Update fileURL in your array based on the fileName
    this.fileURLWithName.forEach((fileInfo: any) => {
      if (fileInfo.originalName === file.name) { // Assuming fileInfo.fileName is the actual file name property
        fileInfo.fileURL = Responseurl.url; // Update with the actual location if available
      }
    });

    // Check if all files have URLs, progress is 100, and other conditions are met
    if (this.checkFileURL() && this.progress === 100 && this.fileURLWithName.length === this.files.length) {
      // this.sendFileInfo(file);
      this.sendFileInfoSinglefile(file,Responseurl.url);
    }
    // this.sharedService.removeFileProgress(file.name);

    this.sendFileInfoSinglefile(file,Responseurl.url);
    // this.sendFileInfo();
  } catch (error) {
    // Handle the error as needed
    // this.sharedService.removeFileProgress(file.name); // Remove progress tracking on error
    console.error('Error getting URL from AWS:', error);
  } /* finally {
    // Always call sendFileInfo, even if an error occurs
    // this.sendFileInfo(file);
    this.sendFileInfoSinglefile(file,Responseurl.url);
  } */
}

 checkFileURL(){
  if(this.fileURLWithName.find((f:any)=>!f.fileURL)){
    return false;
  }else{
    return true;
  }
 }

 sendFileInfo(file: File) {
  let folderId = 0;
  this.route.queryParams.subscribe(params => {
    this.paramsData = params;
      folderId = parseInt(this.paramsData['folderId']);
  });


  for (let i = 0; i < this.files.length; i++) {
    const model = {
        // iFileId: 0,
        iFolderId: folderId,
        fileOriginalName: this.files[i].name,
        fileUrl: this.fileURLWithName.find((file: any) => file.originalName === this.files[i].name)?.fileURL,
        iUserInfoId: Number(this.localStorage.userId),
        sharedEmail: this.sendFileForm.get('email')?.value ? this.sendFileForm.get('email')?.value :null,
        contentType: this.files[i].type,
        description: this.description,
        fileSize: (this.files[i].size).toString(),
        fileType: this.getFileType(this.files[i].type, this.files[i].name),
        longDescription: "string",
        // viewedCount: 0,
        uploadedBy: Number(this.localStorage.userId),
      };

      this.layoutService.postFileDetails(model).subscribe(res => {
        if (res) {
          if (i + 1 === this.fileURLWithName.length) {
            this.progressStart = false;
            this.progress = 0;
            this.sharedService.progress.next(false);
            this.files = [];
            this.fileURLWithName = [];
          }
        }
      });
    }
  }

  async sendFileInfoSinglefile(file: File, url: any) {
    let folderId = 0;
    this.route.queryParams.subscribe(params => {
      this.paramsData = params;
        folderId = parseInt(this.paramsData['folderId']);
    });

    this.furl = url;
    this.ftype = file.type;
    this.sharedService.sendFileForm$.subscribe(form => {
      this.sendFileForm = form == null ? this.sendFileForm : form;
    });

    this.sharedService.description$.subscribe(description => {
      this.description = description;
    });

    this.sharedService.paymentType$.subscribe(description => {
      this.paytype = description;
    });
  
    // for (let i = 0; i < this.files.length; i++) {
      const model = {
          // iFileId: 0,
          iFolderId: folderId ? folderId : 0,
          fileOriginalName: file.name,
          fileUrl: url,
          iUserInfoId: Number(this.localStorage.userId),
          sharedEmail: this.sendFileForm.get('email')?.value ? this.sendFileForm.get('email')?.value :null,
          contentType: file.type,
          description: this.description,
          fileSize: (file.size).toString(),
          fileType: this.getFileType(file.type, file.name),
          longDescription: "string",
          // viewedCount: 0,
          uploadedBy: Number(this.localStorage.userId),
          paymentType: this.paytype,
          Presignedurl: await this.getTinyUrl(await this.loadInitialFileData1(this.furl))
        };
  
        this.layoutService.postFileDetails(model).subscribe(res => {
          this.reloadDashboard();
          if (res) {
            if(folderId != 0){
              this.service.fetFolderDetailsWithFile(folderId).subscribe(async (res) => {
              const data = res.responseData.files
                data.forEach((item: any) =>{
                  if(item.fileOriginalName == file.name){
                    const data1 = res.responseData.sharedEmail
                    data1.forEach((shr: any) =>{
                      const model = {
                        fileId: item.iFileId,
                        iFolderId: folderId,
                        toUserEmails: shr,
                        fromUserEmail: this.localStorage.email, // Replace with actual email
                      };
                      this.service.postAddSharedUser(model).subscribe(res => {          
                        this.reloadDashboard();
                      });
                    })
                  }
                 })
              })
            }
            // if (i + 1 === this.fileURLWithName.length) {
              this.progressStart = false;
              this.progress = 0;
              this.sharedService.progress.next(false);
              this.files = [];
              this.fileURLWithName = [];
            // }
          }
          this.sharedService.DashboardData$.subscribe((files) => {
            for(let i = 0; i < files.length; i++)
          if(this.oncancel2 && files[i].fileOriginalName == model.fileOriginalName){
            this.service.deleteFile(files[i].iFileId).subscribe((res) => {
              this.reloadDashboard();
            console.log("Delete complete", res);
            });  
          }
        });
        });
    this.sendFileForm.reset();
    this.description = '';
    }

    async reloadDashboard(){
      // this.sharedService.isPaid.subscribe(res=>this.ispaid = res);

      if(!this.ispaid){
        this.sharedService.setProgressValue(0);
        this.sharedService.progress.next(false);
        this.sharedService.resetForm();
        this.sharedService.clearDescription();
        this.sharedService.clearFiles();
        this.sharedService.progressstart.next(true);
        try {
          // Ensure furl and ftype are properly initialized
          const originalUrl = this.furl || '';
        
          if (!originalUrl) {
            throw new Error('Invalid URL or file type');
          }
        
          // Construct the custom URL with URL and type as query parameters
          const customUrl = await this.loadInitialFileData1(this.furl);
          
          // Log the custom URL to ensure it is constructed correctly
          console.log('Custom URL:', customUrl);
        
          // Generate the tiny URL and await the result
          const tinyUrl = await this.getTinyUrl(await this.loadInitialFileData1(this.furl));
        
          if (!tinyUrl) {
            throw new Error('Failed to generate tiny URL');
          }
        
          // Set the tiny URL in the shared service
          this.sharedService.ftinyurl.next(tinyUrl);
        
          // Log success
          console.log('Tiny URL successfully set:', tinyUrl);
        
        } catch (error) {
          // Handle any errors and log them
          console.error('Error in generating and setting tiny URL:', error);
        }        
        
        this.sharedService.triggerFunctionCall();
      }
      else{
        this.sharedService.setProgressValue(0);
        this.sharedService.progress.next(false);
        this.dashboardLoadEvent.next(0);
    }
    }

    async loadInitialFileData1(url: any) {
      const s3ObjectKey = url;
      // Generate a pre-signed URL for the S3 object
      const preSignedUrl = await this.generatePreSignedUrl(s3ObjectKey).toPromise();
    
      // Now, you can use the pre-signed URL to fetch the file
      this.selectedVid = preSignedUrl;
      return this.selectedVid;
    }

    generatePreSignedUrl(s3ObjectKey: string): Observable<string> {
      const s3Url = s3ObjectKey;
    
      // Use your backend service or AWS SDK to generate a pre-signed URL
      return this.service.generatePreSignedUrl(s3Url);
    }

    getTinyUrl(url: string): Promise<string> {
      return new Promise((resolve, reject) => {
        this.tinyUrl.shorten(url).subscribe(
          (res: any) => {
            // If you need to replace the domain, uncomment the line below
            // const shortUrl = res.replace('tinyurl.com', 'bharatransfer.com');
            const shortUrl = res;
            resolve(shortUrl);  // Resolve the Promise with the shortened URL
          },
          (error: any) => {
            console.error('Error generating tiny URL:', error);
            reject(error);  // Reject the Promise on error
          }
        );
      });
    }
    
 
  getFileType(type: any, filename: any) {
    const content = type.split('/',2)[0];
    const extenstion = filename.split('.')[1];
    switch(content) { 
      case 'video': { 
        return 'video'
      }
      case 'audio': { 
        return 'audio'
      } 
      case 'image': { 
        return 'image'
      } 
      case 'application': { 
        return (extenstion == 'zip' || extenstion == 'rar') ? 'others' : 'document';
      }
      case 'text': { 
        return 'document'
      }
      default: { 
        return '' 
      } 
  }
}



cancelUploder(){
  this.hideUploader.emit("1");
  this.sendFileForm.reset();
  this.description = '';
}

formatSizeUnits(bytes: any) {
  bytes = Number(bytes);
  if (bytes >= one_GB) { bytes = (bytes / one_GB).toFixed(2) + " GB"; }
  else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
  else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + " KB"; }
  else if (bytes > 1) { bytes = bytes + " bytes"; }
  else if (bytes == 1) { bytes = bytes + " byte"; }
  else { bytes = "0 bytes"; }
  return bytes;
}

validateEmail(control: AbstractControl<any, any>): ValidationErrors | null {
  const sharedEmail = control.value?.replace(/\s+/g, '')
  if(sharedEmail?.indexOf(',') !== -1){
    let emailData = sharedEmail?.split(',');
    if(emailData?.find((email:string)=> !email?.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$"))){
      return { emailInvalid: true };
    }else{
      return null;
    }
  }else{
    if(!sharedEmail?.match("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$")){
      return { emailInvalid: true };
    }else{
      return null;
    }
  }
}

showLoginSuccessFully() {
  this.messageService.add({severity:'success', summary: 'Success', detail: 'Login Successfull'});
}

showFileUploadSuccessFully(data:string) {
 this.messageService.add({ severity: 'custom', summary: data, detail: 'File Uploaded Successfully', icon: 'pi-upload' });
}

showSelectedFileExceedsLimit() {
  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Selected File Exceeded 5GB Limit'});
}

showFailure() {
  this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please select file to upload'});
}

}
