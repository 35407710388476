<style>
      .nav-item.selected,
      .nav-item:hover {
    border-left: 5px solid #499bfc; /* Blue color border for the selected item */
  }

  .nav-item.selected .nav-link span{
    color: #499bfc; /* Blue color for the text */
  }

  .nav-item.selected .nav-link i {
    color: #499bfc; /* Blue color for the icon */
  }
  .nav-item {
    border-left: 5px solid #fdfcfb; /* Default border color */
    color: #545657; /* Default text color */
  }
</style>

<link
      rel="stylesheet"
      data-purpose="Layout StyleSheet"
      title="Web Awesome"
      href="/css/app-wa-a60ddbceb7292f11c9e430d067b1eb9f.css?vsn=d"
    >

      <link
        rel="stylesheet"
        href="https://site-assets.fontawesome.com/releases/v6.5.2/css/all.css"
      >

      <link
        rel="stylesheet"
        href="https://site-assets.fontawesome.com/releases/v6.5.2/css/sharp-thin.css"
      >

      <link
        rel="stylesheet"
        href="https://site-assets.fontawesome.com/releases/v6.5.2/css/sharp-solid.css"
      >

      <link
        rel="stylesheet"
        href="https://site-assets.fontawesome.com/releases/v6.5.2/css/sharp-regular.css"
      >

      <link
        rel="stylesheet"
        href="https://site-assets.fontawesome.com/releases/v6.5.2/css/sharp-light.css"
      >

    <ul class="sidebar navbar-nav  ">
        <!-- <li class="nav-item">
            <a class="nav-link" href="#">
                <!-- <i class="fas fa-fw fa-home"></i>
                <span>Home</span> 
            </a>
        </li> -->

        <li class="channel-sidebar-list">
            <ul *ngIf ="preview">
                <li class="active dis-inline"> <a class="point" (click)="openUploaderModal()"><div class="ics-size"><i class="fa-solid fa-xs fa-plus" style="color: #499BFA; padding-left: 6px;"></i> </div>  </a><a class="pad-5px" (click)="openUploaderModal()">Add File</a></li>
            </ul><ul *ngIf ="preview" style="margin-top: 6px;">
                <li class="dis-inline"><a class="point" (click)="openCreateFolderDialog(false)"><div class="ics-size" style="line-height: 29px;"><i class="fa-light fa-fw fa-folder-plus" style="color: #499BFA; padding-left: 5px;"></i></div>  </a><a class="pad-5px" (click)="openCreateFolderDialog(false)">New Folder</a></li>
            </ul><ul *ngIf ="preview"  style="margin-top: 6px;">
              <li class="active dis-inline"> <a class="point" (click)="openUploadModal()"><div class="ics-size"><i class="fa-solid fa-xs fa-plus" style="color: #499BFA;     padding-left: 6px;"></i></div>  </a> <a class="pad-5px" (click)="openUploadModal()"> Heavy data</a></li>
            </ul>
        </li>
        <h6 class="divider-title">Main Navigation</h6>
        <li class="nav-item" [class]="paramsData ==='0' ? 'selected':''">
            <a class="nav-link nav-set-logo1" [routerLink]="['/collaborate/dashboard']" [class]="paramsData ==='0' ? 'selected':''" [queryParams]="{fileType: 0, folderId: 0}">
                <!-- <i class="fa-sharp fa-grid-2 fa-fw"></i> -->
                <i class="pi pi-microsoft fa-fw"></i>
                <span>My Dashboard</span>
            </a>
        </li>
        <li class="nav-item" [class]="paramsData ==='sent' ? 'selected':''">
            <a class="nav-link nav-set-logo1" [routerLink]="['/collaborate/dashboard']" [class]="paramsData ==='sent' ? 'selected':''" [queryParams]="{fileType: 'sent', folderId: 0}">
                <!-- <i class="fa-sharp fa-solid fa-fw fa-share-nodes"></i> -->
                <i class="fa-solid fa-share-nodes fa-fw"></i>
                <!-- <i class="pi pi-share-alt"></i> -->
                <span>Shared Files</span>
            </a>
        </li>
        <li class="nav-item" [class]="paramsData ==='received' ? 'selected':''">
            <a class="nav-link nav-set-logo1"  [routerLink]="['/collaborate/dashboard']" [class]="paramsData ==='received' ? 'selected':''" [queryParams]="{fileType: 'received', folderId: 0}">
                <i class="fa-solid fa-fw fa-play"></i>
                <!-- <i class="pi pi-play"></i> -->
                <span>Received</span>
            </a>
        </li>
        <li class="nav-item" [class]="paramsData ==='favourites' ? 'selected':''">
            <a class="nav-link nav-set-logo1" [routerLink]="['/collaborate/dashboard']" [class]="paramsData ==='favourites' ? 'selected':''" [queryParams]="{fileType: 'favourites', folderId: 0}">
                <i class="fas fa-fw fa-star"></i>
                <span>Favourites</span>
            </a>
        </li>

        <li class="nav-item" [class]="paramsData ==='profile' ? 'selected':''">
            <a class="nav-link nav-set-logo1" [routerLink]="['/collaborate/profile']" [class]="paramsData ==='profile' ? 'selected':''">
                <i class="fas fa-fw fa-cog"></i>
                <span>Profile</span>
            </a>
        </li>
    </ul>

    <script>
        document.addEventListener('DOMContentLoaded', function () {
          const navItems = document.querySelectorAll('.nav-item');
      
          navItems.forEach(item => {
            item.addEventListener('click', function () {
              // Remove 'selected' class from all navigation items
              navItems.forEach(navItem => {
                navItem.classList.remove('selected');
              });
      
              // Add 'selected' class to the clicked navigation item
              this.classList.add('selected');
            });
          });
        });
      </script>