import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { NgTinyUrlService } from 'ng-tiny-url';
import { DownloadFileModel, SharedService } from '../service/shared.service';
import { ConfirmationService,MessageService, ConfirmEventType } from 'primeng/api';
import { Observable, map, catchError } from 'rxjs';
import * as saveAs from 'file-saver';
import { CollaborateService } from 'src/app/collaborate/services/collaborate.service';
import { AWSS3Service } from '../service/azure-blob-storage.service';
import { AnimationOptions } from 'ngx-lottie';
import {App_Url} from '../../constant/constant'


@Component({
  selector: 'app-sidebar-fileinfo',
  templateUrl: './sidebar-fileinfo.component.html',
  styleUrls: [
  '../../../../css/mystyle.css',
  '../../../../vendor/bootstrap/css/bootstrap.min.css'],
  // '../../../../vendor/fontawesome-free/css/all.min.css']
})
export class SidebarFileinfoComponent implements OnInit ,OnChanges {
  public sendFileForm: FormGroup;
  optionsExclamationAnimation: AnimationOptions = {
    path: 'assets/animations/Animation_change.json',
  };
  public displayBasic: boolean = false;
  public isSubmitted: boolean = false;
  public fileCopied = false;
  public local:any;
  public shortUrl = '';
  displayUserDelete: any=false;
  shrEmail: any;
  @Input() display: any;
  @Input() selectedFile:any;
  @Output() newDisplay = new EventEmitter<string>();
  @Output() showUploaderProgress = new EventEmitter<string>();
  downloadFileList: any;
  tempProgress :any=false;
  tempdownloadProgress :any=false;
  tempDownloadProgressValue : any = 0;
  selectedcom: any;
  ownerprofilePic:any = 'https://www.bharatransfer.com/assets/img/user-icon.png';
  sharetoprofilePic: any = [];
  userInfo:any;
  profilePic:any = 'https://www.bharatransfer.com/assets/img/user-icon.png';
  tempProgressValue: any = 0;
  files: any = [];
  shareemails: any = [];
  
  constructor(public sharedService:SharedService,private collaboratorService: CollaborateService,private messageService: MessageService, private tinyUrl: NgTinyUrlService, private confirmationService: ConfirmationService,private s3Service: AWSS3Service){
    this.sendFileForm = new FormGroup({
      email: new FormControl('', [Validators.required,Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,3}$")]),
    });
    this.sharedService.downloadprogress.subscribe(res=>this.tempdownloadProgress = res)
    this.sharedService.progress.subscribe(res=>this.tempProgress = res);
    this.sharedService.progressValue.subscribe(res=>this.tempProgressValue = res);
    this.sharedService.downloadProgressValue.subscribe(res=>this.tempDownloadProgressValue = res);
  }
  ngOnInit(): void {
    if (localStorage.getItem('userInfo')) {
      this.local = JSON.parse(localStorage.getItem('userInfo') || '');
    }
    this.sharedService.Files$.subscribe((files) => {
      this.files = files;
    });
  }

  ngOnChanges(){
  }

  onClose(){
    this.newDisplay.emit('true');
  }

  closeRemoveDialog(){
    this.displayUserDelete = false;
  }

  onClose1(){
    this.tempProgress = false;
    this.tempProgressValue = 0;
    this.sharedService.oncancelupload(true);
    this.s3Service.cancelUpload();
  }

  onClose12(){
    this.tempdownloadProgress = false;
    this.tempDownloadProgressValue = 0;
    this.sharedService.cancelDownload();
    this.showSuccessFully("Download is cancelled.");
  }

  bytesToSize(bytes: any) {
    bytes = Number(bytes);
    if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(0) + " GB"; }
    else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(0) + " MB"; }
    else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(0) + " KB"; }
    else if (bytes > 1) { bytes = bytes + " bytes"; }
    else if (bytes == 1) { bytes = bytes + " byte"; }
    else { bytes = "0 bytes"; }
    return bytes;
  }

  addRemoveFavourite(folderId: any, fileId: any, favouriote: any, isFolder: any) {
    const id = isFolder == true ? folderId : fileId;
    this.sharedService
      .addRemoveFavourite(id, favouriote, isFolder)
      .subscribe((res) => {
        this.newDisplay.emit('false');
        this.showSuccessFully(res.message);
      });
  }

  async openModal() {
    this.shareemails = [];
    this.sendFileForm.get('email')?.setValue('');
    this.isSubmitted = false;
    this.displayBasic = true;
    const originalUrl = this.selectedFile.fileUrl;
    const fileType = this.selectedFile.fileType;  // The type of the file
    
    // Construct the custom URL with URL and type as query parameters
    const customUrl = App_Url + `/pages/shrfiles?url=${encodeURIComponent(originalUrl)}&type=${encodeURIComponent(fileType)}`;
    
    await this.getTinyUrl(customUrl);
    this.ownerprofilePic = await this.loadInitialFileData1(this.selectedFile.fileUserDetails[0]?.ownerUserProfilePic);
    try {
      const shareduser = this.selectedFile.fileUserDetails.map((user: { sendToUserProfilePic: any; }) => user.sendToUserProfilePic);
      await Promise.all(shareduser.map(async (user: any) => {
          const ss = await this.generatePreSignedUrl(user).toPromise();
          return ss;
      })).then(urls => {
          this.sharetoprofilePic = urls;
          console.log('Pre-signed URLs for shared users:', this.sharetoprofilePic);
      }).catch(error => {
          console.error('Error generating pre-signed URLs:', error);
      });
  } catch (error) {
      console.error('Error processing shared users:', error);
  }
  }

  getTinyUrl(url: any) {
    this.tinyUrl.shorten(url).subscribe((res: any) => {
      this.shortUrl = res;
    });
  }

  addEmail(email: string) {
    if (email && this.validateEmail(email)) {
      this.shareemails.push(email.trim());
      this.sendFileForm.get('email')?.setValue(''); // Clear the input field
    }
  }

  validateEmail(email: string): boolean {
    // Add your email validation logic here
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailPattern.test(email);
  }

  removeEmail(email: string) {
    this.shareemails = this.shareemails.filter((e: string) => e !== email);
  }


  closeDialog() {
    this.isSubmitted = true;
  // Handle the email input and array
    const emailInputValue = this.sendFileForm.get('email')?.value.trim();
    if (emailInputValue) {
      if (this.validateEmail(emailInputValue)) {
          const model = {
            fileId: this.selectedFile.isFolder == false ? this.selectedFile.iFileId : 0,
            iFolderId: this.selectedFile.isFolder == true ? this.selectedFile.iFolderId : 0,
            toUserEmails: emailInputValue,
            fromUserEmail: this.local.email, // Replace with actual email
          };
          this.collaboratorService.postAddSharedUser(model).subscribe(res => {
              this.showSharedSuccessFully();
              this.newDisplay.emit('false');
          });
        // this.shareemails.push(emailInputValue);
        this.sendFileForm.get('email')?.setValue('');
        this.displayBasic = false;
        this.shareemails = [];
        // this.sendFileForm.get('email')?.setValue(''); // Clear the input field
      } else {
        // alert('Please enter a valid email address.');
        return; // Exit if email is invalid
      }
    }

    if (this.sendFileForm.valid && this.shareemails.length > 0) {
      this.shareemails.forEach((sharedEmail: any) => {
        const model = {
          fileId: this.selectedFile.isFolder == false ? this.selectedFile.iFileId : 0,
          iFolderId: this.selectedFile.isFolder == true ? this.selectedFile.iFolderId : 0,
          toUserEmails: sharedEmail,
          fromUserEmail: this.local.email, // Replace with actual email
        };
        this.collaboratorService.postAddSharedUser(model).subscribe(res => {
            this.showSharedSuccessFully();
            this.newDisplay.emit('false');
        });
      });
      this.sendFileForm.get('email')?.setValue('');
      this.displayBasic = false;
      this.shareemails = [];
    }
    this.shortUrl = '';
  }
  showSharedSuccessFully() {
    this.messageService.add({ severity: 'custom', summary: 'Success', detail: 'Shared Successfully', icon: 'pi-comment' });
  }

  confirmRemoveUser(event: any) {
    this.displayUserDelete = true;
    this.shrEmail = event;
    // this.confirmationService.confirm({
    //     message: `Are you sure you want to remove user '${event.sendToEmail.bold()}' ?`,
    //     header: 'Confirmation',
    //     icon: 'pi pi-exclamation-triangle',
    //     accept: () => {
    //       this.removeUser(event);
    //       this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'User is removed from shared list' });
    //     },
    //     reject: (type: any) => {}
    // });
}

  removeUser(event: any) {
    const fileId =
      this.selectedFile.isFolder == false
        ? this.selectedFile.iFileId
        : 0;
    const folderId =
      this.selectedFile.isFolder == true
        ? this.selectedFile.iFolderId
        : 0;
    this.removeUserByApi(fileId, event.sendToEmail, folderId);
  }

  removeUserByApi(fileId: any, email: any, folderId: any) {
    this.sharedService
      .getRemoveSharedFile(fileId, email, folderId)
      .subscribe((res:any) => {
        this.showSuccessFully(res.message);
        this.newDisplay.emit('false');
        this.displayBasic = false;
        this.displayUserDelete = false;
      });
  }

  copyFileURL() {
    this.fileCopied = true;
    setTimeout(() => {
      this.fileCopied = false;
    }, 1000);
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.shortUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  async toDownload(name: string, url: string){
    try {
      this.tempdownloadProgress = true; // Show the progress bar
      this.sharedService.downloadprogress.next(true);

      const fileData = await this.sharedService.downloadFileInPartsByPresignedUrl(
        await this.loadInitialFileData1(url),
        (progress) => {
          // Update the progress value
          this.tempDownloadProgressValue = Math.round((progress.loaded / progress.total) * 100);
          // console.log("Download File",this.tempDownloadProgressValue,"%");
          this.sharedService.setDownloadProgressValue(this.tempDownloadProgressValue);
        }
      );
      const file = new Blob([fileData], { type: 'application/octet-stream' });

      // Use saveAs to trigger the download
      saveAs(file, name || 'downloaded-file');
    } catch (error) {
      console.error('Error downloading file using pre-signed URL:', error);
    } finally {
      this.tempdownloadProgress = false;
      this.sharedService.downloadprogress.next(false); // Hide the progress bar after download completion or failure
    }
}

async loadInitialFileData1(url: any) {
  const s3ObjectKey = url;
  // Generate a pre-signed URL for the S3 object
  const preSignedUrl = await this.generatePreSignedUrl(s3ObjectKey).toPromise();

  // Now, you can use the pre-signed URL to fetch the file
  this.selectedcom = preSignedUrl;
  return this.selectedcom;
}

generatePreSignedUrl(s3ObjectKey: string): Observable<string> {

  const s3Url = s3ObjectKey;

  // Use your backend service or AWS SDK to generate a pre-signed URL
  return this.collaboratorService.generatePreSignedUrl(s3Url);
}

getUserInfo(){
  this.collaboratorService.getUserById(this.local.userId).subscribe(async res => {
    this.userInfo = res.responseData;
    if(this.userInfo && this.userInfo?.profilePicURL !== null){
      this.profilePic = await this.loadInitialFileData1(this.userInfo.profilePicURL);
    } else{
      this.profilePic = 'https://www.bharatransfer.com/assets/img/user-icon.png';
    }
  })
}

showSuccessFully(data: any) {
  this.messageService.add({ severity: 'info', summary: 'Alert!', detail: data, icon: 'pi-link', });
}
}
