import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { DownloadFileModel, SharedService } from '../service/shared.service';
import { Observable, map, catchError } from 'rxjs';
import * as saveAs from 'file-saver';
import { CollaborateService } from 'src/app/collaborate/services/collaborate.service';
import { NgTinyUrlService } from 'ng-tiny-url';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit, OnChanges {
  @Input() files: any = [];
  displayFiles: any = [];
  selectedFile: any;
  selectIndex = 1;
  public sendFileForm: FormGroup;
  public displayBasic: boolean = false;
  public isSubmitted: boolean = false;
  public fileCopied = false;
  public selectedVid: any;
  public local:any;
  public shortUrl = '';
  showFlag: boolean = false;
  selectedImageIndex: number = -1;
  tempProgress :any=false;
  tempDownloadProgressValue : any = 0;
  @Output() newDisplay = new EventEmitter<string>();
  downloadFileList: any;
  signedUrls: any = [];
  ownerprofilePic:any = 'https://www.bharatransfer.com/assets/img/user-icon.png';
  sharetoprofilePic: any = [];
  selectedcom: string | undefined;
  userInfo:any;
  profilePic:any = 'https://www.bharatransfer.com/assets/img/user-icon.png';
  constructor(public sharedService: SharedService, private router: Router,private service: CollaborateService,private tinyUrl: NgTinyUrlService,) {
    this.sendFileForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
    });
    this.sharedService.progress.subscribe(res=>this.tempProgress = res);
    this.sharedService.downloadProgressValue.subscribe(res=>this.tempDownloadProgressValue = res);
   }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.selectIndex = 1;
    this.displayFiles = this.files?.filter((file: any) => file.isFolder === false);
    const videoUrls = this.displayFiles
    .filter((video: { fileType: string; fileUrl: any; }) => (video.fileType === 'video' || video.fileType === 'image') && video.fileUrl)
    .map((video: { fileUrl: any; }) => video.fileUrl);
  
  this.service.generatePreSignedUrls(videoUrls).subscribe(
    urls => {
      this.signedUrls = urls;
    },
    error => console.error('Error:', error)
  );
    this.selectedFile = this.displayFiles[0];
    this.displayFiles.forEach((element: any) => {
      element.image = this.loadInitialFileData(element.fileUrl);
      element.thumbImage = this.loadInitialFileData(element.fileUrl);
      element.alt = 'alt';
      element.title = 'title';
    });
  }
  
  previousFile() {
    let index = this.displayFiles.findIndex((i: any) => this.selectedFile?.iFileId == i?.iFileId);
    this.selectedFile = '';
    this.selectedImageIndex = index;
    if (index > 0) {
      this.selectedFile = this.displayFiles[index - 1]
      this.selectIndex--;
    }
  }

  nextFile() {
    let index = this.displayFiles.findIndex((i: any) => this.selectedFile.iFileId == i.iFileId);
    this.selectedFile = '';
    this.selectedImageIndex = index;
    if (index < this.displayFiles.length - 1) {
      this.selectedFile = this.displayFiles[index + 1]
      this.selectIndex++;
      
    }
    
  }

  contentType(data: any) {
    const content = data?.split('/', 2);
    return (content && content[1]) ? content[1].toUpperCase() : '';
  }

  async loadInitialFileData(url: any) {
    const s3ObjectKey = url;
    // Generate a pre-signed URL for the S3 object
    this.generatePreSignedUrl(s3ObjectKey).subscribe(preSignedUrl => {
      // Now, you can use the pre-signed URL to fetch the file
      this.selectedVid = preSignedUrl;
      
    });
    return this.selectedVid;
  }

  generatePreSignedUrl(s3ObjectKey: string): Observable<string> {
    const s3Url = s3ObjectKey;
  
    // Use your backend service or AWS SDK to generate a pre-signed URL
    return this.service.generatePreSignedUrl(s3Url);
  }

  getTinyUrl(url: any) {
    this.tinyUrl.shorten(url).subscribe((res: any) => {
      this.shortUrl = res;
    });
  }

  getImagetoFileType(type: any) {
    switch (type) {
      case 'application/pdf': {
        return 'assets/images/file-type/pdf.png';
      }
      case 'application/x-zip-compressed': {
        return 'assets/images/file-type/zip.png';
      }
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
        return 'assets/images/file-type/doc.png';
      }
      case 'text/csv': {
        return 'assets/images/file-type/csv.png';
      }
      case 'application/msword': {
        return 'assets/images/file-type/doc.png';
      }
      default: {
        return 'assets/images/file-type/unknown.png';
      }
    }
  }

  videoPreview(id: any) {
    this.router.navigate(['/collaborate/files'], { queryParams: { id: id } });
  }


  bytesToSize(bytes: any) {
    bytes = Number(bytes);
    if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(0) + " GB"; }
    else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(0) + " MB"; }
    else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(0) + " KB"; }
    else if (bytes > 1) { bytes = bytes + " bytes"; }
    else if (bytes == 1) { bytes = bytes + " byte"; }
    else { bytes = "0 bytes"; }
    return bytes;
  }

  addRemoveFavourite(folderId: any, fileId: any, favouriote: any, isFolder: any) {
    const id = isFolder == true ? folderId : fileId;
    this.sharedService
      .addRemoveFavourite(id, favouriote, isFolder)
      .subscribe((res) => {
        this.newDisplay.emit('false');
      });
  }

  async openModal() {
    this.sendFileForm.get('email')?.setValue('');
    this.isSubmitted = false;
    this.displayBasic = true;
    this.getTinyUrl(this.signedUrls[this.selectIndex - 1]);
    this.ownerprofilePic = await this.loadInitialFileData1(this.displayFiles.fileUserDetails[0]?.ownerUserProfilePic);
    try {
      const shareduser = this.selectedFile.fileUserDetails.map((user: { sendToUserProfilePic: any; }) => user.sendToUserProfilePic);

      await Promise.all(shareduser.map(async (user: any) => {
          const ss = await this.generatePreSignedUrl(user).toPromise();
          return ss;
      })).then(urls => {
          this.sharetoprofilePic = urls;
          console.log('Pre-signed URLs for shared users:', this.sharetoprofilePic);
      }).catch(error => {
          console.error('Error generating pre-signed URLs:', error);
      });
  } catch (error) {
      console.error('Error processing shared users:', error);
  }
  }
  
  async loadInitialFileData1(url: any) {
    const s3ObjectKey = url;
    // Generate a pre-signed URL for the S3 object
    const preSignedUrl = await this.generatePreSignedUrl(s3ObjectKey).toPromise();
  
    // Now, you can use the pre-signed URL to fetch the file
    this.selectedcom = preSignedUrl;
    return this.selectedcom;
  }

  closeDialog() {
    this.isSubmitted = true;
    const sharedEmail = this.sendFileForm
      .get('email')
      ?.value.replace(/\s+/g, '');
    if (sharedEmail && this.sendFileForm.valid) {
      const model = {
        fileId:
          this.selectedFile.isFolder == false
            ? this.selectedFile.iFileId
            : 0,
        iFolderId:
          this.selectedFile.isFolder == true
            ? this.selectedFile.iFolderId
            : 0,
        toUserEmails: sharedEmail,
        fromUserEmail: this.local.email,
      };
      this.sharedService.postAddSharedUser(model).subscribe((res) => {
        this.newDisplay.emit('false');
      });
      this.sendFileForm.get('email')?.setValue('');
      this.displayBasic = false;
    }
    this.shortUrl = '';
  }

  removeUser(event: any) {
    const fileId =
      this.selectedFile.isFolder == false
        ? this.selectedFile.iFileId
        : 0;
    const folderId =
      this.selectedFile.isFolder == true
        ? this.selectedFile.iFolderId
        : 0;
    this.removeUserByApi(fileId, event.sendToEmail, folderId);
  }

  removeUserByApi(fileId: any, email: any, folderId: any) {
    this.sharedService
      .getRemoveSharedFile(fileId, email, folderId)
      .subscribe((res:any) => {
        this.newDisplay.emit('false');
        this.displayBasic = false;
      });
  }

  copyFileURL() {
    this.fileCopied = true;
    setTimeout(() => {
      this.fileCopied = false;
    }, 1000);
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.selectedFile.fileUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  async toDownload(name: string, url: string){
    try {
      this.tempProgress = true; // Show the progress bar

      const fileData = await this.sharedService.downloadFileInPartsByPresignedUrl(
        await this.loadInitialFileData(url),
        (progress) => {
          // Update the progress value
          this.tempDownloadProgressValue = Math.round((progress.loaded / progress.total) * 100);
          // console.log("Download File",this.tempDownloadProgressValue,"%");
          this.sharedService.setDownloadProgressValue(this.tempDownloadProgressValue);
        }
      );
      const file = new Blob([fileData], { type: 'application/octet-stream' });

      // Use saveAs to trigger the download
      saveAs(file, name || 'downloaded-file');
    } catch (error) {
      console.error('Error downloading file using pre-signed URL:', error);
    } finally {
      this.tempProgress = false; // Hide the progress bar after download completion or failure
    }
}

  showLightbox() {
    // this.selectedImageIndex = index;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.selectedImageIndex = -1;
  }

  getUserInfo(){
    this.service.getUserById(this.local.userId).subscribe(async res => {
      this.userInfo = res.responseData;
      if(this.userInfo && this.userInfo?.profilePicURL !== null){
        this.profilePic = await this.loadInitialFileData1(this.userInfo.profilePicURL);
      } else{
        this.profilePic = 'https://www.bharatransfer.com/assets/img/user-icon.png';
      }
    })
  }
}
