import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SharedService } from '../service/shared.service';
import { DashboardComponent } from '../../collaborate/components/dashboard/dashboard.component';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['../../../../css/mystyle.css',
  '../../../../vendor/bootstrap/css/bootstrap.min.css',
 '../../../../vendor/fontawesome-free/css/all.min.css']
})
export class SideBarComponent implements OnInit {
  paramsData:any='0';
  showUploader = false;
  displayModal: boolean = false;
  preview: any = true;
  files = [];
  ispaid: any = true;
  constructor(private router:Router,private route: ActivatedRoute, private dashboardComponent: SharedService) {
    router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        if(!event.url.includes('profile')){
          this.paramsData = ((event.url).split("=", 2)[1])?.split('&')[0];
        }else{
          this.paramsData = ((event.url).split("/")[2]);
        }
      }else if(event instanceof NavigationEnd){
        if(!event.url.includes('profile')){
          this.paramsData = ((event.url).split("=", 2)[1])?.split('&')[0];
        }else{
          this.paramsData = ((event.url).split("/")[2]);
        }
      }
    });
   }

  ngOnInit():void {
    this.dashboardComponent.previewObservable().subscribe((isFalse: boolean) => {
      this.preview = isFalse;
    });
    this.dashboardComponent.paidObservable().subscribe((isFalse: boolean) => {
      this.ispaid = isFalse;
    });
  }
  openUploaderModal() {
    // if(!this.ispaid){
      this.dashboardComponent.openUploaderModal();
    // }
    // else{
    //   this.router.navigate(['collaborate/upload']);
    // }
  }

  openUploadModal() {
    // if(!this.ispaid){
    //   this.dashboardComponent.openUploaderModal();
    // }
    // else{
      this.router.navigate(['collaborate/upload']);
    // }
  }

  openCreateFolderDialog(data: any) {
    this.dashboardComponent.openCreateFolderDialog(data);
  }
}
