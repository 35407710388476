<style>
 :host ::ng-deep .p-component, .p-component *{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
</style>
<div  appDnd (fileDropped)="onDNDSelected($event)">
  <div class="file-browse-dialog">
    <div *ngIf="files.length===0 && !progressStart">
      <p-fileUpload (onSelect)="onUpload($event)" [multiple]=true>
        <ng-template pTemplate="content">
          <div class="modal-content">
            <div class="modal-body" style="padding: 0;">
                <div class="video-desc-box">
                    <h6 class="upload-option1">Drop your file here</h6>
                    <!-- <i class="fa fa-upload fa-8x pt-3 pb-2" style="height: 200px; width: 100px;" aria-hidden="true"></i> -->
                    <!-- <ng-lottie width="150px" [options]="optionsAnimation" (click)="fromGlobe()"></ng-lottie> -->
                    <h6 class="upload-option">or</h6>
                    <div class="d-flex justify-content-center align-items-center mt-4">
                      <div class="mt-2 d-flex  justify-content-center align-items-center">
                        <button class="btn-upload1 upload-lh" (click)="fromGlobe()">
                        <i class="pi pi-upload pb-2" aria-hidden="true"></i> &emsp; &emsp; Browse
                      </button>
                      </div>
                      
                    </div>
                    <input type="file" id="fileInput" class="custom-file-input more-file-input" (change)="onSelected($event)">
                </div>
                
                <!-- <div class="video-desc bg-white text-center">
                    <p>Uploading to Dashboard</p>
                </div> -->
            </div>
        </div>
          <!-- <div class="drop-zone d-flex justify-content-cente flex-column align-items-center">
            <h4 class="mb-4">+ Attach files</h4>
            <ng-lottie width="150px" [options]="optionsAnimation" (click)="fromGlobe()"></ng-lottie>
          </div>
          <div>
            <p class="text-center drop-text mt-2">Drop Your Files</p>
             <p class="text-center text-getit">Lets Get It Done Fast!</p> 
            <p class="text-center text-getit">Or</p>
          </div> -->
        </ng-template>
      </p-fileUpload>
      <div class="d-flex mt-2">
        <div class="col-md-4 inputSwitch">
      <p-inputSwitch class="p-0 pl-5 pr-2 " [(ngModel)]="check" (onChange)="inputToEmail()"></p-inputSwitch></div>
      <div class="col-md-8 pl-4"> &emsp; {{inputEmail ? 'Uploading to Dashboard':'Share with others'}}</div>
    </div>
      <div class="video-desc" *ngIf="!inputEmail">
        <form [formGroup]="sendFileForm" class="form-confirmbox justify-content-center d-flex w-full row mb-4" >
        <input type="text" class="form-control text-center"  id="exampleFormControlInput1" placeholder="Send to" [readOnly]="inputEmail"
        formControlName="email">
        <div class="pl-5" style="height: 10px;" *ngIf="(sendFileForm.get('email')?.touched || isSubmitted)">
          <span class="email-text" *ngIf="sendFileForm.get('email')?.hasError('required')">
            *Email is Required
          </span>
          <span class="email-text" *ngIf="sendFileForm.get('email')?.hasError('pattern')">
            *Please enter correct email id
          </span>
        </div>
        </form>
    </div>
    <div class="video-desc" style="display: flex; justify-content: center;">
        <textarea rows="5"   id="description" name="description" class="form-control text-center" placeholder="Description" [(ngModel)]="description" pInputTextarea>Description</textarea>
    </div>
    <div class="center-button justify-content-evenly">
      <button class="btn btn-dark1 btn-upload" tabindex="0" (click)="sendFileGetURL()">{{inputEmail ? 'Upload':'Send'}}</button>
      <button class="btn btn-cls btn-cancle" tabindex="1" type="button" data-dismiss="modal" (click)="cancelUploder()">Cancel</button>
  </div>
    </div>
    </div>
    <!----addfile html start -->
    <div *ngIf="files.length>0 && !progressStart">
      <div>
        <!-- <div class="card add-more-card mb-4">
          <div class="row m-0">
    
            <div class="col-md-6 px-0">
              <div class="d-flex pt-3 addmore-left align-items-start" (click)="fromGlobe()">
                <div class="plus-icon pt-2 pl-3">
                  <i class="pi pi-plus plus-icon mr-2"></i>
                </div>
                <div class="drop-file pl-4 text-white text-left">
                  <input type="file" class="more-file-input" (change)="onSelected($event)">
                  <p>{{files.length}} file added</p>
                </div>
              </div>
              <div class="uploaded-file">
                <p *ngFor="let item of files;let i = index" class="fs-6 uploaded-file-name mb-0">
                  <span> <i class="pi pi-times-circle pr-2" (click)="remove(i)"></i></span>
                  {{ item.name | longName : 15 :15}}
                </p>
              </div>
            </div>
    
            <div class="col-md-6 addmore-right pb-4 pt-2">
              <div class=" form-check form-switch upload-switch d-flex mt-2 pl-0">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
                <p-inputSwitch class="p-0 pl-4" (onChange)="inputToEmail()"></p-inputSwitch>
                <label class="d-inline form-check-label switch-label mt-1" for="flexSwitchCheckDefault">Upload To Your Dashboard</label>
              </div>
              <div *ngIf="!inputEmail">
                <form [formGroup]="sendFileForm" class="form-confirmbox d-flex w-full row  pt-0" >
                
                  <input type="text" class="email-input mt-2"  id="exampleFormControlInput1" placeholder="Send to" [readOnly]="inputEmail"
                  formControlName="email">
          
                  <div class="text-danger text-center"
                            *ngIf="isSubmitted && sendFileForm.get('email')?.hasError('required')">
                            Please Enter E-mail
                        </div>
                        <div class="text-danger text-center"
                            *ngIf="isSubmitted &&  sendFileForm.get('email')?.hasError('emailInvalid') && !sendFileForm.get('email')?.hasError('required')">
                            Plese Enter Valid E-mail
                        </div>
                  </form>
              </div>
              <div [class]="!inputEmail ? 'decription-opt' : 'decription-switch-on'">
                <textarea maxlength="50" class="homepage-textarea" rows="3" cols="50" [(ngModel)]="description" pInputTextarea></textarea>
                <input type="text" class="form-control"  placeholder="Description" rows="3"
                    [(ngModel)]="description">
            </div>
              <!-- <textarea class="form-control" placeholder="Description" rows="3" [(ngModel)]="description"></textarea> 
              <div class="d-flex justify-content-end">
                <button class="upload-button" (click)="sendFileGetURL()">{{inputEmail ? 'Upload':'Send'}}</button>
                <!-- <button class="cancle-button" (click)="cancelUploder()">Cancel</button>
              </div>
            </div>
          </div>
        </div> -->
        <div class="modal-content">
          <div class="modal-body" style="padding: 0;">
              <div class="video-desc-box">
                  <div class="upload-option2 video-title" *ngFor="let item of files;let i = index">
                    <ul style="list-style-type: none;">
                      <li><span> <i class="pi pi-times-circle pr-2" (click)="remove(i)"></i></span>{{ item.name | longName : 15 :15}}</li>
                    </ul>
                  </div>
                  <div class="video-details">
                    <div class="mt-2 d-flex  justify-content-center align-items-center">
                      <button class="btn-upload1 upload-lh" (click)="fromGlobe()">
                      <i class="pi pi-upload pb-2" aria-hidden="true"></i> &emsp; &emsp; Browse
                    </button>
                    </div>
                    <input type="file" id="fileInput" class="custom-file-input more-file-input" (change)="onSelected($event)">
                  </div>
              </div>
              <div class="d-flex mt-2">
                <div class="col-md-4 inputSwitch">
              <p-inputSwitch class="p-0 pl-5 pr-2 " [(ngModel)]="check" (onChange)="inputToEmail()"></p-inputSwitch></div>
              <div class="col-md-8 pl-4"> &emsp; {{inputEmail ? 'Uploading to Dashboard':'Share with others'}}</div>
            </div>
            <div class="video-desc" *ngIf="!inputEmail">
              <form [formGroup]="sendFileForm" class="form-confirmbox justify-content-center d-flex w-full row mb-4" >
              <input type="text" class="form-control text-center"  id="exampleFormControlInput1" placeholder="Send to" [readOnly]="inputEmail"
              formControlName="email">
              <div class="pl-5" style="height: 10px; " *ngIf="(sendFileForm.get('email')?.touched || isSubmitted)">
                <span class="email-text" *ngIf="sendFileForm.get('email')?.hasError('required')">
                  *Email is Required
                </span>
                <span class="email-text" *ngIf="sendFileForm.get('email')?.hasError('pattern')">
                  *Please enter correct email id
                </span>
              </div>
              </form>
          </div>
              <div class="video-desc" style="display: flex; justify-content: center;">
                  <textarea   rows="5" id="description" name="description" class="form-control text-center" placeholder="Description" [(ngModel)]="description" pInputTextarea>Description</textarea>
              </div>
          </div>
          
          <div class="center-button justify-content-evenly">
            <button class="btn btn-dark1 btn-upload" tabindex="0" (click)="sendFileGetURL()">{{inputEmail ? 'Upload':'Send'}}</button>
              <button class="btn btn-cls btn-cancle" tabindex="1" type="button" data-dismiss="modal" (click)="cancelUploder()">Cancel</button>
          </div>
      </div>
      </div>
    </div>
</div>
