import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpResponse, S3 } from 'aws-sdk';
import { float } from 'aws-sdk/clients/cloudfront';
import { BehaviorSubject, Observable, Subject, from, map } from 'rxjs';
import { APIConstant } from 'src/app/constant/api';
import { bucket_name, region_name, accessKey_Id, secretAccess_Key } from 'src/app/constant/constant';

import { Readable } from 'stream';

export interface DownloadFileModel{
  fileName: any,
  url: any,
  tempDownloadProgressValue: number,
  id: number
}

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  bucket = bucket_name;
  region = region_name;
  accessKeyId = accessKey_Id;
  secretAccessKey = secretAccess_Key;

  s3: S3;
  private abortController: AbortController | null = null;

  constructor(private httpClient: HttpClient) { 
    this.s3 = new S3({
    accessKeyId: this.accessKeyId,
    secretAccessKey: this.secretAccessKey,
    region: this.region
  });
}

private uploaderModalSubject = new Subject<void>();
private createFolderDialogSubject = new Subject<boolean>();


// Subjects to request function calls from the dashboard component
private requestSelectFile = new Subject<any>();
private requestSearchBar = new Subject<any>();

// Observables for the requests
requestSelectFile$ = this.requestSelectFile.asObservable();
requestSearchBar$ = this.requestSearchBar.asObservable();


// Methods to emit requests
requestSelectFileEvent(event: any) {
  this.requestSelectFile.next(event);
}

requestSearchBarEvent(event: any) {
  this.requestSearchBar.next(event);
}

  // Subject for the filtered file list
  private filteredFilesSubject = new Subject<any[]>();
  filteredFiles$ = this.filteredFilesSubject.asObservable();

  // Method to emit filtered file list
  updateFilteredFiles(files: any[]) {
    this.filteredFilesSubject.next(files);
  }

openUploaderModal() {
  this.uploaderModalSubject.next();
}

  // Subject for the filtered file list
  private filesSubject = new Subject<any[]>();
  Files$ = this.filesSubject.asObservable();

  // Method to emit filtered file list
  setfiles(files: any[]) {
    this.filesSubject.next(files);
  }

openCreateFolderDialog(isFalse: boolean) { 
  this.createFolderDialogSubject.next(isFalse);
}

getUploaderModalObservable() {
  return this.uploaderModalSubject.asObservable();
}

getCreateFolderDialogObservable() {
  return this.createFolderDialogSubject.asObservable();
}

previewDialog(isFalse: boolean) { 
  this.Preview.next(isFalse);
}

previewObservable() {
  return this.Preview.asObservable();
}

paidDialog(isFalse: boolean) { 
  this.ispaid.next(isFalse);
}

paidObservable() {
  return this.ispaid.asObservable();
}

uploadDialog(isFalse: boolean) { 
  this.Upload.next(isFalse);
}

uploadObservable() {
  return this.Upload.asObservable();
}

cancelupload = new Subject<boolean>();

oncancelupload(isFalse: boolean) { 
  this.cancelupload.next(isFalse);
}

oncanceluploadObservable() {
  return this.cancelupload.asObservable();
}

  // Subject for the filtered file list
  private DashboardData = new Subject<any[]>();
  DashboardData$ = this.DashboardData.asObservable();

  // Method to emit filtered file list
  DataArray(files: any[]) {
    this.DashboardData.next(files);
  }

  // public downloadFile(fileName: any, url: any) {
  //   return this.httpClient.get(url,
  //     {
  //       params: fileName,
  //       responseType: 'blob',
  //       reportProgress: true,
  //       observe: 'events',
  //       headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream' })
  //     }
  //   );
  // }

  public downloadFile(Name: any): Observable<any> {
    const urlParts = Name.split('/');
    const params = {
      Bucket: this.bucket,
      Key: urlParts[urlParts.length - 1],
    };

    return new Observable((observer) => {
      this.s3.headObject(params, (headErr, headData) => {
        if (headErr) {
          observer.error(headErr);
        } else {
          this.s3.getObject(params, (getErr, getData) => {
            if (getErr) {
              observer.error(getErr);
            } else {
              observer.next(getData.Body);
            }
            observer.complete();
          });
        }
      });
    });
  }

  async downloadFileInPartsByPresignedUrl(presignedUrl: string,progressCallback: (progress: ProgressEvent) => void): Promise<Uint8Array> {
    this.abortController = new AbortController();
    const { signal } = this.abortController;
    
    const response = await fetch(presignedUrl);

    if (!response.ok) {
      throw new Error(`Failed to fetch the file. Status: ${response.status}`);
    }

    const totalSize = parseInt(response.headers.get('content-length') || '0', 10);
    const chunkSize = 10 * 1024 * 1024; // 1 MB chunks (you can adjust this value)
    const result = new Uint8Array(totalSize);
    let offset = 0;

    for (let start = 0; start < totalSize; start += chunkSize) {
      const end = Math.min(start + chunkSize, totalSize) - 1;
      const range = `bytes=${start}-${end}`;

      // Download each chunk and update progress
      const chunkData = await this.downloadChunkWithProgress(presignedUrl, range, progressCallback);

      // Combine the chunks into a single Uint8Array
      result.set(chunkData, offset);
      offset += chunkData.length;

      // Trigger the progress callback for the chunk download
      const loaded = Math.min(start + chunkSize, totalSize);
      progressCallback({
        loaded, total: totalSize,
        lengthComputable: false,
        target: null,
        bubbles: false,
        cancelBubble: false,
        cancelable: false,
        composed: false,
        currentTarget: null,
        defaultPrevented: false,
        eventPhase: 0,
        isTrusted: false,
        returnValue: false,
        srcElement: null,
        timeStamp: 0,
        type: '',
        composedPath: function (): EventTarget[] {
          throw new Error('Function not implemented.');
        },
        initEvent: function (type: string, bubbles?: boolean | undefined, cancelable?: boolean | undefined): void {
          throw new Error('Function not implemented.');
        },
        preventDefault: function (): void {
          throw new Error('Function not implemented.');
        },
        stopImmediatePropagation: function (): void {
          throw new Error('Function not implemented.');
        },
        stopPropagation: function (): void {
          throw new Error('Function not implemented.');
        },
        NONE: 0,
        CAPTURING_PHASE: 1,
        AT_TARGET: 2,
        BUBBLING_PHASE: 3
      });
            // Check if the download has been aborted
            if (signal.aborted) {
              throw new Error('Download aborted');
            }
    }

    return result;
  }
  
  private async downloadChunkWithProgress(presignedUrl: string, range: string, onProgress: (progressEvent: ProgressEvent) => void): Promise<Uint8Array> {
    const chunkResponse = await fetch(presignedUrl, { headers: { Range: range } });

    if (!chunkResponse.ok) {
      throw new Error(`Failed to fetch chunk. Status: ${chunkResponse.status}`);
    }

    const chunkData = await chunkResponse.arrayBuffer();

    // Trigger the progress callback for the chunk download
    onProgress({
      loaded: chunkData.byteLength, total: chunkData.byteLength,
      lengthComputable: false,
      target: null,
      bubbles: false,
      cancelBubble: false,
      cancelable: false,
      composed: false,
      currentTarget: null,
      defaultPrevented: false,
      eventPhase: 0,
      isTrusted: false,
      returnValue: false,
      srcElement: null,
      timeStamp: 0,
      type: '',
      composedPath: function (): EventTarget[] {
        throw new Error('Function not implemented.');
      },
      initEvent: function (type: string, bubbles?: boolean | undefined, cancelable?: boolean | undefined): void {
        throw new Error('Function not implemented.');
      },
      preventDefault: function (): void {
        throw new Error('Function not implemented.');
      },
      stopImmediatePropagation: function (): void {
        throw new Error('Function not implemented.');
      },
      stopPropagation: function (): void {
        throw new Error('Function not implemented.');
      },
      NONE: 0,
      CAPTURING_PHASE: 1,
      AT_TARGET: 2,
      BUBBLING_PHASE: 3
    });

    return new Uint8Array(chunkData);
  }

  cancelDownload() {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = null;
    }
  }

  public getFileSizeByUserId(iUserId: any): Observable<any>{
    const url = `${APIConstant.fileSizeByUserId}`
    return this.httpClient.get(url.replace('{iUserId}' , iUserId))
  }

  public _downloadfile$ = new BehaviorSubject<Array<DownloadFileModel>>([]);
  get downloadfile$(): Observable<Array<DownloadFileModel>> { return this._downloadfile$.asObservable(); }

  Preview = new Subject<boolean>();
  ispaid = new Subject<boolean>();
  Upload = new Subject<boolean>();
  
 // get user(): DownloadFileModel { return this._user$.getValue()[0]; }
  progress = new Subject<boolean>();
  downloadprogress = new Subject<boolean>();
  progressValue = new Subject<any>();
  downloadProgressValue = new Subject<any>();
  downloadFilesValue = new Subject<DownloadFileModel[]>();
  progressstart = new Subject<boolean>();
 // Inside sharedService
ftinyurl = new BehaviorSubject<string>('');  // Initialize with an empty string or default value

  upSize = new Subject<any>();

  // Inside your sharedService
totalSize = new BehaviorSubject<number>(0); // Initialize with a default value

setTotalSize(size: number) {
  this.totalSize.next(size); // Emit new value when set
}

  // Add this method to your SharedService
setProgressValue(progress: number): void {
  this.progressValue.next(progress);
}

setupSize(progress: number): void {
  this.upSize.next(progress);
}

setDownloadProgressValue(progress: number): void {
  this.downloadProgressValue.next(progress);
}



  public getGetNotificationByUserId(iUserId:any): Observable<any>{
    const url = `${APIConstant.getGetNotificationByUserId}`
    return this.httpClient.get(url.replace('{iUserId}' , iUserId))
  }

  public getGetNewNotification(iUserId:any): Observable<any>{
    const url = `${APIConstant.getGetNewNotification}`
    return this.httpClient.get(url.replace('{iUserId}' , iUserId))
  }

  public getReadNotificationById(iNotificationId:any): Observable<any>{
    const url = `${APIConstant.getReadNotificationById}`
    return this.httpClient.get(url.replace('{iNotificationId}' , iNotificationId))
  }

  public addRemoveFavourite(iFileFolderId: any, isFavourite: any, isFolder: any): Observable<any> {
    const url = `${APIConstant.addRemoveFavourite}`
    return this.httpClient.get(url.replace('{iFileFolderId}', iFileFolderId).replace('{isFavourite}', isFavourite).replace('{isFolder}', isFolder))
  }

  public postAddSharedUser(model: any): Observable<any> {
    const url = `${APIConstant.postAddSharedUser}`
    return this.httpClient.post(url, model)
  }

  public getRemoveSharedFile(iFileId: any, userEmails: any, iFolderId: any): Observable<any> {
    const url = `${APIConstant.getRemoveSharedFile}`
    return this.httpClient.get(url.replace('{iFileId}', iFileId).replace('{iFolderId}', iFolderId).replace('{userEmails}', userEmails))
  }

  // Prepaid upload model
  private sendFileFormSubject = new BehaviorSubject<any>(null);
  private descriptionSubject = new BehaviorSubject<string>('');
  private UfilesSubject = new BehaviorSubject<any[]>([]);

  sendFileForm$ = this.sendFileFormSubject.asObservable();
  description$ = this.descriptionSubject.asObservable();
  files$ = this.UfilesSubject.asObservable();

  setSendFileForm(form: any) {
    this.sendFileFormSubject.next(form);
  }

  setDescription(description: string) {
    this.descriptionSubject.next(description);
  }

  setFiles(files: any[]) {
    this.UfilesSubject.next(files);
  }

  resetForm() {
    this.sendFileFormSubject.value?.reset();
  }

  clearDescription() {
    this.descriptionSubject.next('');
  }

  clearFiles() {
    this.UfilesSubject.next([]);
  }

  // upload process area

  
private uploadprocessModalSubject = new Subject<any>();
private upprocessModalSubject = new Subject<any>();

getUploadProcessModalObservable() {
  return this.uploadprocessModalSubject.asObservable();
}

openUploadProcessModal(files: any) {
  this.uploadprocessModalSubject.next(files);
}

getUpProcessModalObservable() {
  return this.upprocessModalSubject.asObservable();
}

openUpProcessModal(files: any) {
  this.upprocessModalSubject.next(files);
}

private totalsizeSubject = new BehaviorSubject<number>(0);
totalsize$ = this.totalsizeSubject.asObservable();

settotalsize(size: number) {
  this.totalsizeSubject.next(size);
}

cleartotalsize() {
  this.totalsizeSubject.next(0);
}

private callFunctionSource = new Subject<void>();

  // Observable that will be triggered when we want to call the function
  callFunction$ = this.callFunctionSource.asObservable();

  // Method to trigger the function
  triggerFunctionCall() {
    this.callFunctionSource.next();
  }

  private paymentTypeSubject = new BehaviorSubject<string>('');

  paymentType$ = this.paymentTypeSubject.asObservable();

  setPaymentType(paymentType: string) {
    this.paymentTypeSubject.next(paymentType);
  }
}
