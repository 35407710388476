<p-sidebar [(visible)]="display" position="right" [modal]="false" (onHide)="onClose()" *ngIf="selectedFile">
    <div class="file-info-sidebar">
        <!-- <div class="title-and-option d-flex justify-content-between align-items-center py-2">
            <h4 class="m-0">File Info</h4>
            <div class="option-box d-flex m-0 p-0">
                <button type="button" class="btn btn-sent ml-1">
                    <i *ngIf="selectedFile?.isFavourite == false" class="fa fa-star-o ng-star-inserted"
                                (click)="addRemoveFavourite(selectedFile.iFolderId,selectedFile.iFileId, true,selectedFile.isFolder)"></i>
                    <i *ngIf="selectedFile?.isFavourite == true" class="fa fa-star ng-star-inserted"
                                (click)="addRemoveFavourite(selectedFile.iFolderId,selectedFile.iFileId, false,selectedFile.isFolder)"></i>
                </button>
                <button type="button" class="btn btn-sent ml-1" (click)="openModal()"><i class="pi pi-share-alt"></i></button>
                <button type="button" class="btn btn-sent ml-1" (click)="toDownload(selectedFile.fileOriginalName,selectedFile.fileUrl)"><i class="pi pi-download "></i></button>
            </div>
            <div class="slider-border-box"></div>
        </div>
        <div class="file-details-box py-3">
            <p class="pb-2">File name: <span>{{selectedFile?.fileOriginalName}}</span></p>
            <p class="pb-2">File size : <span>{{selectedFile.isFolder == false ? this.bytesToSize(this.selectedFile?.fileSize) : this.bytesToSize(this.selectedFile?.folderSize)}}</span></p>
            <p>Format : <span>{{selectedFile.isFolder == false ? selectedFile.contentType : 'Folder'}}</span></p>
        </div>
        <div class="file-details-box pt-5" *ngIf="selectedFile?.description && selectedFile.isFolder == false">
            <h4 class="m-0">Description</h4>
            <p class="pb-2">{{selectedFile?.description}}</p>
        </div> -->
        <!-- *ngIf="selectedFile?.fileUserDetails.length>0" -->
        <!-- <div class="file-details-box py-3" *ngIf="selectedFile.isFolder == false && selectedFile?.fileUserDetails.length>0">
            <h4 class="pb-2">File Shared With:</h4>
            <div class="shared-details d-flex align-items-center justify-content-between" *ngFor="let email of selectedFile?.fileUserDetails ">
                <p>{{email?.sendToEmail}}</p>
                <i  *ngIf="selectedFile.fileUserDetails[0]?.ownerEmail == local.email" class="fa fa-times-circle-o close-shared" aria-hidden="true" (click)="confirmRemoveUser(email)"></i>
            </div>
            <div class="shared-details">
                <p>Faisal George</p>
                <i class="fa fa-times-circle" aria-hidden="true"></i>
            </div>
            <div class="shared-details  d-flex align-items-center">
                <p>Alexander Brith</p>
                <i class="fa fa-times-circle-o" aria-hidden="true"></i>
            </div>
        </div>
        <div class="file-details-box activity-box py-3">
            <h4 class="pb-2">Activity:</h4>
            <div *ngIf="this.selectedFile.isFolder === false">
            <p class="">File uploaded On {{this.selectedFile.fileActivity?.fileUploadedDate| date :'dd MMM yyyy'}}</p>
            <p class="pb-2 text-xs" *ngIf="selectedFile?.fileUserDetails.length>0">Shared with: </p>
            

            <div *ngIf="selectedFile?.fileUserDetails.length>0">
            <div class="user-data d-flex align-items-center" *ngFor="let share of this.selectedFile.fileUserDetails">
                <div class="user-image mr-5"><img [src]="share.sendToUserProfilePic"></div>
                <span> - {{share.sendToUserName}}</span>
            </div>
            </div>

            <p class="pb-2 duplicate-file" *ngIf="this.selectedFile.fileActivity.fileDuplicatedBy !== null "> <span class="py-2">File Duplicated by: </span>
                <span>{{this.selectedFile.fileActivity.fileDuplicatedBy}} On {{this.selectedFile.fileActivity.fileDuplicateDate| date :'dd MMM yyyy'}}</span>
            </p>
            <p class="pb-2 duplicate-file" *ngIf="this.selectedFile.fileActivity.fileReviewedBy !== null "> <span class="py-2">File Reviewed by: </span>
                <span>{{this.selectedFile.fileActivity.fileReviewedBy}} On {{this.selectedFile.fileActivity.fileReviewedDate| date :'dd MMM yyyy'}}</span>
            </p>

            </div>
            
            <div *ngIf="this.selectedFile.isFolder === true">
                <p class="">Folder Created On {{this.selectedFile.folderActivity.folderCreatedDate | date :'dd MMM yyyy'}}</p>
            <p class="pb-2 text-xs">Folder Contains :</p>
            <p class="pb-2" *ngFor="let file of this.selectedFile.folderActivity.folderFileNames ;let i = index">{{i+1}}. {{file}}</p>

            </div>
        </div>-->
        <div class="file-info-heading" style="border-bottom: 2px solid #999999;
        opacity: 1;">
            <h6>File Info</h6>
            <ul>
                <li><button type="button" class="btn btn-sent">
                    <i *ngIf="selectedFile?.isFavourite == false" class="pi pi-star"
                                (click)="addRemoveFavourite(selectedFile.iFolderId,selectedFile.iFileId, true,selectedFile.isFolder)"></i>
                    <i *ngIf="selectedFile?.isFavourite == true" class="pi pi-star-fill ng-star-inserted"
                                (click)="addRemoveFavourite(selectedFile.iFolderId,selectedFile.iFileId, false,selectedFile.isFolder)"></i>
                </button></li>
                <li><button type="button" class="btn btn-sent" (click)="openModal()"><i class="pi pi-share-alt"></i></button></li>
                <li><button type="button" class="btn btn-sent" (click)="toDownload(selectedFile.fileOriginalName,selectedFile.fileUrl)"><i class="pi pi-download "></i></button></li>
            </ul>
        </div>
        <div class="file-info-name-size">
            <h4>File Name: {{selectedFile?.fileOriginalName}}</h4>
            <h4>File Size: {{selectedFile.isFolder == false ? this.bytesToSize(this.selectedFile?.fileSize) : this.bytesToSize(this.selectedFile?.folderSize)}}</h4>
            <h4>Format: {{selectedFile.isFolder == false ? selectedFile.contentType : 'Folder'}}</h4>
        </div>
        <div class="file-info-name-size">
            <h5 class="file-desc-title">File Description</h5>
            <p class="file-desc">{{selectedFile?.description}}</p>
        </div>
        <div class="file-info-name-size">
            <h5 class="file-desc-title">File Shared With:</h5>
            <div *ngFor="let email of selectedFile?.fileUserDetails">
            <p class="file-shared-with">{{email?.sendToEmail | longName : 20 :20}} <a *ngIf="selectedFile.fileUserDetails[0]?.ownerEmail == local.email" class="close-shared" aria-hidden="true" (click)="confirmRemoveUser(email)"><i class="fas fa-xmark"></i></a></p>
        </div>
        </div>
        <div class="file-info-name-size" *ngIf="this.selectedFile.fileActivity?.fileReviewedBy!== null">
            <h5 class="file-desc-title">File Reviewed by:</h5>
            <p class="file-desc">{{this.selectedFile.fileActivity.fileReviewedBy}} on {{this.selectedFile.fileActivity.fileReviewedDate| date :'dd MMM yyyy'}} at {{this.selectedFile.fileActivity.fileReviewedDate| date :'HH:mm:ss'}}</p>
        </div>
        <!-- <div class="file-info-name-size">
            <h5 class="file-desc-title">Activity</h5>
            <div *ngIf="this.selectedFile.isFolder === false">
                <p class="file-desc">File uploaded On {{this.selectedFile.fileActivity?.fileUploadedDate| date :'dd MMM yyyy'}}</p>
                <p class="pb-2 text-xs file-desc" *ngIf="selectedFile?.fileUserDetails.length>0">Shared with: </p>
                
    
                <div *ngIf="selectedFile?.fileUserDetails.length>0">
                <div class="user-data d-flex align-items-center file-desc" *ngFor="let share of this.selectedFile.fileUserDetails">
                    <div class="user-image mr-5"><img [src]=sharetoprofilePic></div>
                    <span class="file-desc"> - {{share.sendToUserName | longName : 15 :20}}</span>
                </div>
                </div>
    
                <p class="pb-2 duplicate-file" *ngIf="this.selectedFile.fileActivity.fileDuplicatedBy !== null "> <span class="py-2">File Duplicated by: </span>
                    <span class="file-desc">{{this.selectedFile.fileActivity.fileDuplicatedBy}} On {{this.selectedFile.fileActivity.fileDuplicateDate| date :'dd MMM yyyy'}}</span>
                </p>
                <p class="pb-2 duplicate-file" *ngIf="this.selectedFile.fileActivity.fileReviewedBy !== null "> <span class="py-2">File Reviewed by: </span>
                    <span class="file-desc">{{this.selectedFile.fileActivity.fileReviewedBy}} On {{this.selectedFile.fileActivity.fileReviewedDate| date :'dd MMM yyyy'}}</span>
                </p>
    
                </div>
                
                <div *ngIf="this.selectedFile.isFolder === true">
                    <p class="file-desc">Folder Created On {{this.selectedFile.folderActivity.folderCreatedDate | date :'dd MMM yyyy'}}</p>
                <p class="pb-2 text-xs file-desc">Folder Contains :</p>
                <p class="pb-2 file-desc" *ngFor="let file of this.selectedFile.folderActivity.folderFileNames ;let i = index">{{i+1}}. {{file}}</p>
    
                </div>
        </div> -->
    </div>
</p-sidebar>


<!-- ------------------------Share File Modal ------------------------- -->
<div class="sent-share-dailogs" *ngIf="selectedFile">
    <p-dialog [header]='"Share "+ (selectedFile.isFolder === true ? selectedFile.folderName : selectedFile.fileOriginalName) | longName : 20 :20' [modal]="true" [dismissableMask]="true" [draggable]="false"
[(visible)]="displayBasic" [style]="{width: ''}" [style]="{height: ''}" id="shareModal">
<ng-template pTemplate="header">
    <span pTooltip="{{selectedFile.isFolder === true ? selectedFile.folderName : selectedFile.fileOriginalName}}" tooltipPosition="top">
        {{ 'Share ' + (selectedFile.isFolder === true ? selectedFile.folderName : selectedFile.fileOriginalName | longName: 20: 20) }}
    </span>
  </ng-template>
<form [formGroup]="sendFileForm" class="form-confirmbox">
    <div class="modal-content">
        <div class="modal-body">
            <div class="form-group share-email-box">
                <input 
                #emailInput 
                type="text" 
                pInputText 
                class="form-control" 
                placeholder="Enter email...." 
                formControlName="email" 
                appEmailValidator 
                autofocus
            >
            <!-- <input 
                #emailInput 
                type="text" 
                pInputText 
                class="form-control" 
                placeholder="Enter email...." 
                formControlName="email" 
                appEmailValidator 
                (keydown.enter)="addEmail(emailInput.value)" 
                autofocus
            > -->
                <button class="btn btn-primary btn-dark1 btn-dark share-email-btn" (click)="closeDialog()"><p class="ng-white pd">Share</p></button>
            </div>
            <div class="pl-1" style="height: 10px; margin-left: 29px;" *ngIf="(sendFileForm.get('email')?.touched || isSubmitted)">
                <span class="email-text" *ngIf="sendFileForm.get('email')?.hasError('required')">
                  *Email is Required
                </span>
                <span class="email-text" *ngIf="sendFileForm.get('email')?.hasError('pattern')">
                  *Please enter correct email id
                </span>
              </div>
              <h6 class="manyemail" *ngFor="let itm of shareemails">
                {{itm | longName : 5 :10}}
                <i class="fas fa-xmark" aria-hidden="true" (click)="removeEmail(itm)"></i>
            </h6>
            <!-- <h5 class="file-shared-status">File shared successfully</h5> -->
            <div class="share-admin-box">
                <ul>
                    <li>
                        <div class="comment_user_icon">
                            <img [src]=ownerprofilePic class="icon" (error)="profilePic = 'https://www.bharatransfer.com/assets/img/user-icon.png'">
                        </div>
                        <div class="user_data">
                            <h5 class="user_name">{{ selectedFile.fileUserDetails.length>0 ? selectedFile.fileUserDetails[0]?.ownerUserName : userInfo?.firstName + ' ' + userInfo?.lastName}}</h5>
                            <p class="user_email">{{ selectedFile.fileUserDetails.length>0 ? selectedFile.fileUserDetails[0]?.ownerEmail : selectedFile?.ownerEmail}}</p>
                        </div>
                        <div class="add-remove-btn">
                            <a href="" class="btn btn-admin">Admin</a>
                        </div>
                    </li>
                    <h6 *ngIf="selectedFile.fileUserDetails.length>0">Shared with</h6>
                    <li *ngFor="let email of selectedFile?.fileUserDetails; let index = index">
                        <div class="comment_user_icon">
                            <img [src]=sharetoprofilePic[index] class="icon" (error)="profilePic = 'https://www.bharatransfer.com/assets/img/user-icon.png'">                        </div>
                        <div class="user_data">
                            <h5 class="user_name">{{email.sendToUserName}}</h5>
                            <p class="user_email">{{email.sendToEmail}}</p>
                        </div>
                        <div class="add-remove-btn">
                            <a class="btn-remove"><i class="fas fa-xmark" aria-hidden="true" (click)="confirmRemoveUser(email)"></i></a>
                        </div>
                    </li>
                </ul>
            </div>
            <div *ngIf="selectedFile.isFolder == false">
            <div class="copy-link-box">
                <input type="text" class="form-control" placeholder="" [value]=shortUrl readonly="true">
                <a class="btn copy-link-btn" (click)="copyFileURL()">Copy Link</a>
            </div>
            <div class="row linheight">
            <h6 class="link-copied-text col-md-7">Copied link will allow user to view or download the file. User cannot add comment.</h6>
            <span class="copied-text col-md-4">{{fileCopied ? 'Copied':''}}</span>
          </div>
        </div>
        </div>
    </div>
</form>
</p-dialog>
</div>
<!-- ------------------------Share File Modal ------------------------- -->

<!-- ------------------------remove share user Modal Starts---------------------- -->
<div class="review-dialogue" style="z-index: 11100 !important;
background: radial-gradient(#bcbcbc, #bcbcbc73) !important;">
  <p-dialog class="review-header" [header]=' "Confirmation"' [modal]="true" [dismissableMask]="true" [draggable]="false"
    [(visible)]="displayUserDelete" [style]="{minWidth: '327px', maxWidth: '327px', borderRadius: '30px'}" [baseZIndex]="10000">
    <div class="d-flex justify-content-center">
      <ng-lottie width="150px" [options]="optionsExclamationAnimation"></ng-lottie>
    </div>
    <div class="text-center" style="width: 100%;
    display: flex;
    justify-content: center;">
      <p style="font: normal normal 12px/14px Inter;
      font-weight: 600;
      letter-spacing: 0px;
      color: #1B0C2D;
      opacity: 1;">Are you sure you want to remove user {{shrEmail?.sendToEmail}}?</p>
    </div>
    <ng-template pTemplate="footer" style="display: flex; justify-content: space-evenly;">
      <p-button label="Remove" styleClass="custom-p-button1 btn-dark1" severity="contrast" (click)="removeUser(shrEmail)"/>
      <p-button label="Cancel" styleClass="custom-p-button" (click)="closeRemoveDialog()"/>
    </ng-template>
  </p-dialog>
</div>
<!-- --------------------------remove share user Modal End------------------------- -->

<!-- --------------------------Progressbar Start------------------------- -->
<div>
    <!-- <p class="mb-1">File Uploading - ({{tempDownloadProgressValue}}%)</p> -->
    <!-- <p-progressBar [value]="tempDownloadProgressValue" [showValue]="false"></p-progressBar> -->
    <!-- <div class="download-popup" *ngFor="let item of files; let i = index" [ngStyle]="{'bottom': i * 8.7 + 'em'}">
        <div class="modal-content">
            <div class="modal-body" >
        <div class="progress-button">
                <div class="video-progress">
                    <h6 class="file_upload_title">Uploading</h6>
                    <p-progressBar class="progressbar" [value]="item.tempProgressValue" [showValue]="false">
                </p-progressBar>
                <label class="counter">({{item.tempProgressValue}}%)</label>
                </div>
                <button class="btn btn-cancledownload" type="button" data-dismiss="modal" (click)="onClose1()">Cancel</button>
            </div>
            </div>
        </div>
        </div> -->
</div>
<!-- <div class=" file_upload_progress" *ngIf="tempProgress" >
    <div class="modal-dialog" *ngIf="tempProgressValue !== 100" >
    <div class="modal-content">
        <div class="modal-body" >
            <div class="progress-button">
                <div class="video-progress">
                    <h6 class="file_upload_title">Uploading</h6>
                    <p-progressBar class="progressbar" [value]="tempProgressValue" [showValue]="false">
                </p-progressBar>
                <label class="counter">({{tempProgressValue}}%)</label>
                </div>
                <button class="btn btn-cancle" type="button" data-dismiss="modal" (click)="onClose1()">Cancel</button>
            </div>
        </div>
    </div>
</div>
</div>
<div class=" file_upload_progress" *ngIf="tempdownloadProgress">
    <div class="modal-dialog" *ngIf="tempDownloadProgressValue !== 100">
    <div class="modal-content" >
        <div class="modal-body" >
            <div class="progress-button">
                <div class="video-progress">
                    <h6 class="file_upload_title">Download File</h6>
                    <p-progressBar class="progressbar" [value]="tempDownloadProgressValue" [showValue]="false">
                </p-progressBar>
                <label class="counter">({{ tempDownloadProgressValue }}%)</label>
                </div>
                <button class="btn btn-cancledownload" type="button" data-dismiss="modal" (click)="onClose12()">Cancel</button>
            </div>
        </div>
    </div>
</div>
</div> -->
<!-- <div *ngIf="tempdownloadProgress">
    <div class="download-popup mt-0" *ngIf="tempDownloadProgressValue !== 100">
      <div>
        <!-- Add your icon or styling here 
      </div>
      <div style="width: 100%;">
        <div class="download-title d-flex justify-content-start p-2">
          <h3>Download File</h3>
        </div>
        <div class="progress-box-download p-2">
          <p class="mb-1">Download file - ({{ tempDownloadProgressValue }}%)</p>
          <p-progressBar [value]="tempDownloadProgressValue" [showValue]="false"></p-progressBar>
        </div>
      </div>
    </div>
     <!-- <div class="fileupload-popup m-2" *ngIf="tempProgressValue===100">
        <div class="mr-3">
          <i class="fa fa-cloud-upload" aria-hidden="true"></i>
         <ng-lottie width="50px" [options]="optionsDone"></ng-lottie>
        </div>
        <div>
         <p class="mb-0 text-white">File uploaded</p>
        </div>
     </div> 
</div> -->
<!-- --------------------------Progressbar End------------------------- -->