import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import * as moment from 'moment';
import { AnimationOptions } from 'ngx-lottie';
import { SharedService } from '../service/shared.service';
import jwt_decode from "jwt-decode";
import { CollaborateService } from 'src/app/collaborate/services/collaborate.service';
import { Observable } from 'rxjs';
import '../../../../vendor/bootstrap/js/bootstrap.bundle.min.js';
import { AutoComplete } from 'primeng/autocomplete';
import { event } from 'jquery';


@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['../../../../css/mystyle.css',
  '../../../../vendor/bootstrap/css/bootstrap.min.css',
  '../../../../vendor/fontawesome-free/css/all.min.css']
})
export class SubHeaderComponent implements OnInit {
   optionsLoaderAnimation: AnimationOptions = {
    path: "assets/animations/loading-content.json"
  };
  showUploader = false;
  menuShow = false;
  displayModal: boolean = false;
  loaderContent : boolean = false;
  notificationPanel:boolean = false;
  files = [];
  local:any;
  notification :any = [];
  selectedFile: any[] = [];
  suggestions: any = [];
  unreadNotification = '0';
  userInfo:any;
  profilePic:any = 'https://www.bharatransfer.com/assets/img/user-icon.png';
  selectedcom: any;
  username: any = "";
  text: any;
  upload: any = true;
  @ViewChild('autoCompleteRef') autoCompleteRef!: AutoComplete;

  // Method to clear the input of the p-autoComplete component
 

  constructor(private router:Router,private sharedService:SharedService, private collaboratorService: CollaborateService){
    router.events.subscribe((val) => {
      if(val instanceof NavigationStart) {
        this.menuShow = true;
        this.notificationPanel = true;
        this.newNotification();
      }
  });
  this.sharedService.uploadObservable().subscribe((isFalse: boolean) => {
    this.upload = isFalse;
  });
   }

  ngOnInit() {
    if(localStorage.getItem('userInfo')){
      this.local = JSON.parse(localStorage.getItem('userInfo') || '');
      this.getUserInfo();
      this.newNotification();
      
    }
    this.sharedService.filteredFiles$.subscribe((files) => {
      this.suggestions = files;
    });
  }
  
  clearAutoComplete() {
    this.autoCompleteRef.clear();
    this.requestSearchBar(event);
  }
  onClear21() {
    this.text = '';
    this.clearAutoComplete();
  }

  getUserInfo() {
    let localData = JSON.parse(localStorage.getItem('userInfo') || '{}')
    this.collaboratorService.getUserById(localData.userId).subscribe(async res => {
      this.userInfo = res.responseData;
      if(this.userInfo && this.userInfo?.profilePicURL !== null){
        this.profilePic = await this.loadInitialFileData1(this.userInfo.profilePicURL);
        this.username = this.userInfo.firstName;
      }
      else if (localData.profilePicture != "")
        {
          this.profilePic = await this.loadInitialFileData1(localData.profilePicture);
          this.username = localData.username;
        }
      else{
        this.profilePic = 'https://www.bharatransfer.com/assets/img/user-icon.png';
      }
    })
  }

    // Request the selectFile function in the dashboard component
    requestSelectFile(event: any) {
      this.sharedService.requestSelectFileEvent(event);
    }
  
    // Request the searchBar function in the dashboard component
    requestSearchBar(event: any) {
      this.sharedService.requestSearchBarEvent(event);
    }

  async loadInitialFileData1(url: any) {
    const s3ObjectKey = url;
    // Generate a pre-signed URL for the S3 object
    const preSignedUrl = await this.generatePreSignedUrl(s3ObjectKey).toPromise();
  
    // Now, you can use the pre-signed URL to fetch the file
    this.selectedcom = preSignedUrl;
    return this.selectedcom;
  }

  generatePreSignedUrl(s3ObjectKey: string): Observable<string> {

    const s3Url = s3ObjectKey;
  
    // Use your backend service or AWS SDK to generate a pre-signed URL
    return this.collaboratorService.generatePreSignedUrl(s3Url);
  }


  calculateDiff(dateSent:any){
    let currentDate = new Date();
    dateSent = new Date(dateSent);
    return Math.abs((Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) ) /(1000 * 60 * 60 * 24));
}

clickedOutside(): void {
  this.menuShow = false;
}

clickedOutside2(){
  this.notificationPanel = false;
}

toNavigate(navigateTo:any ,value:any){
  if(value){
    this.router.navigate([navigateTo], { fragment: value });
  }else{
    this.router.navigate([navigateTo]);
  }
}

  calculate(dateSent:any){
  let dateTime = moment.utc(dateSent).toDate();
  return dateTime;
  }

  logoutUser(){
    localStorage.removeItem('userInfo');
    localStorage.setItem('status','logged-out')
    this.router.navigate(['/auth/login']);
  }

  openNotificationPanel(){
    this.notificationPanel = !this.notificationPanel
    if(this.notificationPanel){
      this.openNotification();
    }
  }

  newNotification(){
    this.sharedService.getGetNewNotification(this.local.userId).subscribe(res=>{ 
      this.unreadNotification  = res.responseData
    });
  }

  openNotification(){
    this.loaderContent = true;
    this.sharedService.getGetNotificationByUserId(this.local.userId).subscribe(async res=>{
      this.loaderContent = false;
      this.notification = res.responseData;
      for(let i=0; i<this.notification.length; i++){
        if(this.notification[i].isActive){
          this.sharedService.getReadNotificationById(this.notification[i].iNotificationId).subscribe(res=>{this.newNotification();})
        }
      }
    })
  }

  openModal() {
    this.displayModal = true;
    this.files = [];
  }

  closeDialog(){
    this.displayModal = false;
  }

  onFileUploaded(data: string){
   if(data === '1'){
    this.displayModal = false;
   }
  }

  cancelUploader(data: string){
    if(data === '1'){
      this.displayModal = false;
     }
  }
}


