// export const Razorpay_key: string = 'rzp_test_4z8YeVwCBZBkCd';
export const Razorpay_key: string = 'rzp_live_7wKUJR2eGmCBEw'; //live


export const chuck_size: number = 10 * 1024 * 1024;

// export const App_Url: string = 'http://localhost:4200';
export const App_Url: string = 'https://www.bharatransfer.com'; //live

export const bucket_name: string = 'atulbmumbai';
export const region_name: string  = 'ap-south-1';
export const accessKey_Id: string  = 'AKIAY4FN7ANJJ4XBJGM6';
export const secretAccess_Key: string  = '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';

// export const bucket_name: string = 'prevueit-doc';
// export const region_name: string  = 'ap-south-1';
// export const accessKey_Id: string  = 'AKIAY4FN7ANJB6IKB6GZ';
// export const secretAccess_Key: string  = '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';

export const upload_size: number = 10 * 1024 * 1024 * 1024;

export const one_GB: number = 1 * 1024 * 1024 * 1024;
